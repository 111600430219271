import {ANALYTICS_ENABLED} from '../../configs/vars';
import {AddAnalyticEventDocument, AnalyticEventType} from '../../graphql-types';
import graphQLClient from '../graphql/client';
import getPageviewValue from './getPageviewValue';

let logPageviewTimeout: NodeJS.Timeout | undefined;

const logPageview = (pathname: string) => {
    clearTimeout(logPageviewTimeout);

    if (!ANALYTICS_ENABLED || !localStorage?.accountState) {
        return;
    }

    logPageviewTimeout = setTimeout(() => {
        try {
            graphQLClient.mutate({
                mutation: AddAnalyticEventDocument,
                variables: {
                    input: {
                        type: AnalyticEventType.Pageview,
                        value: getPageviewValue(pathname),
                        pageUrl: pathname,
                    },
                },
            }).then(() => { }).catch(() => { });
        } catch (e) { }
    }, 2000);
};

export default logPageview;
