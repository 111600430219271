enum AnalyticEventValues {
    embed = 'embed',
    export = 'download',
    exportAll = 'export_all_button',
    exportIcon = 'export_icon',
    filters = 'filters',
    copy = 'copy_icon',
    youtube = 'youtube_icon',
}

export default AnalyticEventValues;
