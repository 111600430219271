// eslint-disable-next-line no-unused-vars
import type {GatsbyBrowser} from 'gatsby';
import {Helmet} from 'react-helmet';
import './src/assets/styles/core.css';
import {PropsWithChildren, useEffect} from 'react';
import store from 'store/dist/store.modern';
import {ApolloProvider} from '@apollo/client/react';
import {ToastContainer} from 'react-toastify';
import {userSettingsVar} from './src/helpers/graphql/variables';
import graphQLClient from './src/helpers/graphql/client';
import logPageview from './src/helpers/analytics/logPageview';

const UserSettingsProvider = ({children}: PropsWithChildren) => {
    useEffect(() => {
        const getInitialUserSettings = () => {
            if (!store.get('userSettings')) {
                store.set('userSettings', {});
            }

            return store.get('userSettings');
        };

        userSettingsVar(getInitialUserSettings());
    }, []);

    return (<>{children}</>);
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({location}) => logPageview(location.pathname);

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => (
    <UserSettingsProvider>
        <ApolloProvider client={graphQLClient}>
            <Helmet>
                <meta name="theme-color" content="#ffffff" />
                <link rel="icon" href="/images/favicons/favicon.svg" />
                <link rel="apple-touch-icon" href="/images/favicons/apple-touch-icon.png" />
                <link rel="manifest" href="/images/favicons/manifest.json" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
            </Helmet>
            {element}
            <ToastContainer
                position="top-right"
                autoClose={15000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </ApolloProvider>
    </UserSettingsProvider>
);
